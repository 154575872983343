
import { defineComponent } from 'vue';
import { Button } from '@/components'
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
import { parseProxyToObject } from '@/utils'
import store from '@/store'
export default defineComponent({
  name : 'Login',
  components:{
    SuiteInput,
    Button
  },
  data(){
    return{
      email:"",
      password:"",
      loginError:""
    }
  },
  methods:{
    logIn(){
      this.loginError = ""
      let errors = [
        parseProxyToObject(this.$refs.Email).hasErrors(),
        parseProxyToObject(this.$refs.Password).hasErrors()
      ]
      if(errors.includes(true)) return

      store.dispatch('loginClient',{email:this.email,password:this.password})
      .then((data) => {
        if(data.authToken){
          this.getClientData()
        }
        else this.loginError = this.$t('suite_login_error')
      })
    },
    async getClientData(){
      store.dispatch('getClientData',{email:this.email,password:this.password})
      .then((data) => {
        if(data.active2fa) this.goTo2FA()
        else this.$router.push('/dashboard')
      })
    },
    async goTo2FA(){
      await store.dispatch('setCookieNeeds2FA')
      this.$router.push('/2fa')
    }
  }
})
