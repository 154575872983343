export const parseProxyToObject = function(proxy: any){
  let x = new Proxy(proxy,{})
  return x
}

export const numberCounter = function(obj: any, start: any, end: any, duration: any){
  let startTimestamp:any = null;
  const step = (timestamp:any) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.innerHTML = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

export const dragScroll = (element: any) => {
  const slider = document.querySelector(element);
  let isDown = false;
  let startX: any;
  let scrollLeft: any;

  slider.addEventListener('mousedown', (e: any) => {
    isDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
  });
  slider.addEventListener('mousemove', (e: any) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3;
    slider.scrollLeft = scrollLeft - walk;
  });
}